import L from '@/utils/leaflet.js'
import moment from 'moment'
import { mapState } from 'vuex'
import { debounce } from 'lodash'

const kdTree = require('kd-tree-javascript/kdTree-min.js')

export default {
  data() {
    return {
      timeArrows: {},
      directionArrows: {},
      lastDistance: '',
      zoomReports: true,
      zoomMonitoring: true,
      lastItemName: '',
      annData: [],
      listMarkers: {}
    }
  },
  computed: {
    ...mapState('reports', {
      tracksToDrawCall: state => state.tracksToDrawCall
    }),

    zoom() {
      if (this.map) {
        return this.map.getZoom()
      }
      return 10
    }
  },
  watch: {},

  methods: {
    clearTimeArrows(itemNames) {
      itemNames.forEach(itemName => {
        if (this.timeArrows[itemName]) {
          clearTimeout(this.timeArrows[itemName])
        }
      })
    },

    clearDirectionsArrows(itemNames) {
      itemNames.forEach(itemName => {
        if (this.directionArrows[itemName]) {
          this.directionArrows[itemName].removeFrom(this.map)
          this.directionArrows[itemName] = null
        }
      })

      this.$store.commit('reports/CLEAR_DRAWENARROWS')
    },


    /**
     * Fr.AV
     * Отрисовка аннотация к треку,
     * таблички со скоростью и т.п.
     * */
    showDirectivesForDebounce(val, itemName) {
      if (this.isReportClosed || val.track.values[0] === undefined) return

      const lat = val.track.values[0].points.length > 2 ? val.track.values[0].points[0][1] : ''
      const lng = val.track.values[0].points.length > 2 ? val.track.values[0].points[0][2] : ''
      const nextLat = val.track.values[0].points.length > 2 ? val.track.values[0].points[1][1] : ''
      const nextLng = val.track.values[0].points.length > 2 ? val.track.values[0].points[1][2] : ''

      const dates = [
        {
          coords: [lat, lng],
          next: [nextLat, nextLng]
        }
      ]

      this.annData = []
      this.listMarkers[itemName] = []
      const tree = new kdTree.kdTree([dates[0].coords], this.getDistance, ['0', '1'])

      const bounds = this.map.getBounds()
      const southWest = bounds.getSouthWest()
      const northEast = bounds.getNorthEast()
      const _distance = southWest.distanceTo(northEast) * 0.07
      val.track.values.forEach(track => {
        track.points.forEach((p, key) => {
          const pointsP = L.latLng([p[1], p[2]])
          if (bounds.contains(pointsP)) {
            const nearest = tree.nearest([p[1], p[2]], 1)
            if (L.latLng(nearest[0][0]).distanceTo(pointsP) > _distance) {
              // set time
              const trackTime = new Date(1970, 0, 1)
              trackTime.setUTCSeconds(p[0])
              const date = moment(new Date(trackTime)).format('DD-MM HH:mm')
              this.annData.push([date, p[3]])
              // end

              if (track.points[key + 1]) {
                this.listMarkers[itemName].push({
                  coords: [p[1], p[2]],
                  next: [track.points[key + 1][1], track.points[key + 1][2]]
                })
                tree.insert([p[1], p[2]])
              }
            }
          }
        })
      })
      if (this.directionArrows[itemName]) {
        this.directionArrows[itemName].removeFrom(this.map)
      }
      this.directionArrows[itemName] = L.featureGroup(this.getArrows(this.listMarkers[itemName], 'red', 1, this.map, this.annData)).addTo(this.map)

      const trekSignature = document.querySelectorAll('.trek-signatures')
      const arrowsDirection = document.querySelectorAll('.arrow-svg .arrow-svg-path')

      arrowsDirection.forEach(item => {
        if (this.isHidingSignatures) {
          item.style.fill = 'rgba(255, 255, 255, 0)'
        } else {
          item.style.fill = '#F56C6C'
        }
      })
      trekSignature.forEach(item => {
        if (this.isHidingSignatures) {
          item.style.display = 'none'
        } else {
          item.style.display = 'block'
        }
      })
      this.$store.commit('reports/SET_DRAWENARROWS', this.directionArrows)
      // console.log('>>> showDirectives showDirectivesForDebounce')
    },

    showDirectivesDebounce: debounce(function (val, itemName) {
      this.showDirectivesForDebounce(val, itemName)
    }, 1000),

    showDirectives(val, itemName) {
      if (this.isReportClosed) {
        return
      }

      let startPointIndexTree = 0
      if (val.track[0].points.length < 2) {
        startPointIndexTree = 1
      }

      const dates = [
        {
          coords: [val.track[startPointIndexTree].points[0][1], val.track[startPointIndexTree].points[0][2]],
          next: [val.track[startPointIndexTree].points[1][1], val.track[startPointIndexTree].points[1][2]]
        }
      ]

      if (val.id) {
        // if (this.lastDistance !== distance) {
        this.clearTimeArrows([itemName])
        if (this.directionArrows[itemName]) {
          this.directionArrows[itemName].removeFrom(this.map)
        }
        if (!this.tracksToDrawCall) {
          this.timeArrows[itemName] = setTimeout(() => {
            this.annData = []
            this.listMarkers[itemName] = []
            const tree = new kdTree.kdTree([dates[0].coords], this.getDistance, ['0', '1'])

            const bounds = this.map.getBounds()
            const southWest = bounds.getSouthWest()
            const northEast = bounds.getNorthEast()
            const _distance = southWest.distanceTo(northEast) * 0.07
            val.track.forEach(track => {
              track.points.forEach((p, key) => {
                const pointsP = L.latLng([p[1], p[2]])
                // if(pointsP.lat > southWest.lat && pointsP.lng < northEast.lng) {
                if (bounds.contains(pointsP)) {
                  const nearest = tree.nearest([p[1], p[2]], 1)
                  if (L.latLng(nearest[0][0]).distanceTo(pointsP) > _distance) {
                    // set time
                    const trackTime = new Date(1970, 0, 1)
                    trackTime.setUTCSeconds(p[0])
                    const date = moment(new Date(trackTime)).format('MM-DD HH:mm')
                    this.annData.push([date, p[3]])
                    // end

                    if (track.points[key + 1]) {
                      this.listMarkers[itemName].push({
                        coords: [p[1], p[2]],
                        next: [track.points[key + 1][1], track.points[key + 1][2]]
                      })
                      tree.insert([p[1], p[2]])
                    }
                  }
                }
                // let firstPoint = L.latLng(nearest[0][0])
                // let secondPoint = L.latLng([p[1], p[2]])
                // let b = firstPoint.distanceTo(secondPoint)
              })
            })

            const trekSignature = document.querySelectorAll('.trek-signatures')
            trekSignature.forEach(item => {
              item.style.display = 'block'
              //   if (this.isHidingSignatures) {
              //     console.log('display NONE')
              //     item.style.display = 'none'
              //   } else {
              // item.style.display = 'block'
              //     console.log('display block')
              //   }
            })

            this.$store.commit('reports/SET_DRAWENARROWS', this.directionArrows)
          }, 0)
        }
      }
      this.timeArrows[itemName] = setTimeout(() => {
        this.annData = []
        this.listMarkers[itemName] = []
        const tree = new kdTree.kdTree([dates[0].coords], this.getDistance, ['0', '1'])

        const bounds = this.map.getBounds()
        const southWest = bounds.getSouthWest()
        const northEast = bounds.getNorthEast()
        const _distance = southWest.distanceTo(northEast) * 0.07
        val.track.forEach(track => {
          track.points.forEach((p, key) => {
            const pointsP = L.latLng([p[1], p[2]])
            // if(pointsP.lat > southWest.lat && pointsP.lng < northEast.lng) {
            if (bounds.contains(pointsP)) {
              const nearest = tree.nearest([p[1], p[2]], 1)
              if (L.latLng(nearest[0][0]).distanceTo(pointsP) > _distance) {
                // set time
                const trackTime = new Date(1970, 0, 1)
                trackTime.setUTCSeconds(p[0])
                const date = moment(new Date(trackTime)).format('MM-DD HH:mm')
                this.annData.push([date, p[3]])
                // end

                if (track.points[key + 1]) {
                  this.listMarkers[itemName].push({
                    coords: [p[1], p[2]],
                    next: [track.points[key + 1][1], track.points[key + 1][2]]
                  })
                  tree.insert([p[1], p[2]])
                }
              }
            }
            // let firstPoint = L.latLng(nearest[0][0])
            // let secondPoint = L.latLng([p[1], p[2]])
            // let b = firstPoint.distanceTo(secondPoint)
          })
        })
        if (this.directionArrows[itemName]) {
          this.directionArrows[itemName].removeFrom(this.map)
        }
        this.directionArrows[itemName] = L.featureGroup(this.getArrows(this.listMarkers[itemName], 'red', 1, this.map, this.annData)).addTo(this.map)

        const trekSignature = document.querySelectorAll('.trek-signatures')
        const arrowsDirection = document.querySelectorAll('.arrow-svg .arrow-svg-path')
        arrowsDirection.forEach(item => {
          if (this.isHidingSignatures) {
            item.style.fill = 'rgba(255, 255, 255, 0)'
          } else {
            item.style.fill = '#F56C6C'
          }
        })
        trekSignature.forEach(item => {
          if (this.isHidingSignatures) {
            item.style.display = 'none'
          } else {
            item.style.display = 'block'
          }
        })
        this.$store.commit('reports/SET_DRAWENARROWS', this.directionArrows)
      }, 0)
    },

    getDistance(firstPoint, secondPoint) {
      const deltaX = firstPoint[0] - secondPoint[0]
      const deltaY = firstPoint[1] - secondPoint[1]
      return deltaX * deltaX + deltaY * deltaY
    },

    getArrows(arrLatlngs, color, arrowCount, mapObj, annData) {
      if (typeof arrowCount === 'undefined' || arrowCount == null) arrowCount = 1

      if (typeof color === 'undefined' || color == null) color = ''
      else color = `color:${color}`

      const result = []
      for (let i = 1; i < arrLatlngs.length; i++) {
        if (arrLatlngs[i - 1].next[0]) {
          const icon = L.divIcon({
            className: 'arrow-icon show-direction-arrows',
            iconSize: [8, 5],
            iconUrl: '/static/ArrowForReport.svg',
            iconAnchor: [4, 5],
            html: `` + `<div style="display: flex; ${color};transform: rotate(${this.getAngle([arrLatlngs[i - 1].coords[0], arrLatlngs[i - 1].coords[1]], [arrLatlngs[i - 1].next[0], arrLatlngs[i - 1].next[1]], -1).toString()}deg)">` + `<svg class="arrow-svg" style="transform: rotate(-90deg)" width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">` + `<path class="arrow-svg-path" d="M12.9984 1.21607L7.28056 5.49317L2.51903 0.171952L0.635718 1.58071L6.96557 8.6546L14.5667 2.96874L12.9984 1.21607Z" fill="#F56C6C"/>` + `</svg>` + `</div>`
          })
          for (let c = 1; c <= arrowCount; c++) {
            result.push(
              L.marker([arrLatlngs[i - 1].coords[0], arrLatlngs[i - 1].coords[1]], {
                icon
              }).bindTooltip(
                `<div class="arrow-direction__info">
            <div class="flex items-center">
              <p style="white-space: nowrap">${annData[i - 1][0]}</p>
            </div>
            <div class="flex justify-center">
              <p>${annData[i - 1][1] ? annData[i - 1][1] : 0} км/ч</p>
            </div>
          </div>`,
                {
                  permanent: true,
                  direction: 'right',
                  className: 'trek-signatures',
                  offset: L.point(-50, -30)
                }
              )
            )
          }
        }
      }
      return result
    },

    getAngle(latLng1, latlng2, coef) {
      const dy = latlng2[0] - latLng1[0]
      const dx = Math.cos((Math.PI / 180) * latLng1[0]) * (latlng2[1] - latLng1[1])
      const ang = (Math.atan2(dy, dx) / Math.PI) * 180 * coef
      return ang.toFixed(2)
    }
  }
}
